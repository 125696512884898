import AxiosInstance from "@/core/services/axios.service";

const headers = {
  "Content-Type": "multipart/form-data",
};

const DocumentService = {
  getDocuments(params) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get("documents", {
        headers: headers,
        params: params,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  getDocument(id) {
    return new Promise((resolve, reject) => {
      AxiosInstance.get(`documents/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  uploadFile(file, params) {
    const formData = new FormData();
    formData.append("file", file);

    return new Promise((resolve, reject) => {
      AxiosInstance.post(
        "documents",
        formData,
        {
          params: params,
        },
        {
          headers: headers,
        }
      )
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  // Get file from server by path and return blob
  getFile(path) {
    return new Promise((resolve, reject) => {
      fetch(path, { responseType: "arrayBuffer" })
        .then((response) => {
          resolve(response.blob());
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteFile(fileId) {
    return new Promise((resolve, reject) => {
      AxiosInstance.delete(`documents/${fileId}`, {
        headers: headers,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  updateFileName(fileId, fileName) {
    return new Promise((resolve, reject) => {
      AxiosInstance.put(`documents/${fileId}`, { name: fileName })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  },
  createPreviewLinkOfDocument(document) {
    return process.env.VUE_APP_BASE_URL + document.path;
  },
};

export default DocumentService;
