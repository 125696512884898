<template>
  <v-card>
    <v-toolbar color="primary" class="rounded-0 text-white" height="80px">
      <v-toolbar-title class="ml-3">
        <h2><b>Uygulamalar</b> - Hatırlatıcılar</h2>
      </v-toolbar-title>
      <v-spacer />
      <div class="d-flex align-items-center">
        <v-btn
          color="error"
          class="mr-2"
          v-if="areRemindersIncludeCompletedReminders"
          small
          prepend-icon="mdi-delete"
          @click="() => deleteAllCompletedReminders()"
        >
          <span>Tamamlanan Hatırlatıcıları Sil</span>
        </v-btn>
        <!-- <v-btn
          small
          prepend-icon="mdi-alarm-plus"
          color="success"
          class="mr-2"
          @click="openReminderEditModal()"
        >
          <span>Hatırlatıcı Ekle</span>
        </v-btn> -->
      </div>
      <KTTopMenu></KTTopMenu>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="mappedReminders"
        :search="search"
        :item-class="changeRowBGColor"
        :loading="loading"
        item-key="id"
      >
        <template #item.actions="{ item }">
          <div class="d-flex justify-content-around">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2 cursor-pointer"
                  :class="{ 'text-primary': item.status !== 'Tamamlandı' }"
                  :disabled="item.status === 'completed'"
                  v-bind="attrs"
                  v-on="on"
                  @click="setReminderStatusAsComplete(item)"
                >
                  mdi-check
                </v-icon>
              </template>
              <span>Tamamlandi Olarak Isaretle</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="text-warning mr-2 cursor-pointer"
                  v-bind="attrs"
                  v-on="on"
                  @click="openReminderEditModal(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Duzenle</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="text-danger mr-2 cursor-pointer"
                  v-bind="attrs"
                  v-on="on"
                  @click="showDeleteModal(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Sil</span>
            </v-tooltip>
          </div>
        </template>
        <template #item.status="{ item }">
          <v-chip small :color="getReminderStatusColor(item.status)">
            {{ item.status_text }}
          </v-chip>
        </template>
        <template #item.lead_id="{ item }">
          <router-link
            :to="{
              name: 'lead-show',
              params: { id: item.lead_id },
            }"
          >
            <span v-on="on" v-bind="attrs">{{ item.lead_name }}</span>
          </router-link>
        </template>
        <template #item.subject="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <Note
                :ref="`note-${item.id}`"
                :note_id="item.note_id"
                :name="item.lead_name"
                :note_type="item.note_type"
                :clearData="false"
                :noteable_id="item.note.noteable_id"
                :noteable_type="item.note.noteable_type"
              >
                <span v-bind="attrs" class="text-primary" v-on="on">
                  {{
                    item.subject.length > 30
                      ? item.subject.substring(0, 30) + "..."
                      : item.subject
                  }}</span
                >
              </Note>
            </template>
            <span>Notu Goster</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <ReminderEditModal />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import KTTopMenu from "@/view/layout/header/TopMenu.vue";
import {
  FETCH_REMINDERS,
  DELETE_REMINDER,
  DELETE_COMPLETED_REMINDERS,
  COMPLETE_REMINDER_STATUS,
} from "@/core/services/store/reminders.module";
import ReminderEditModal from "./ReminderEditModal.vue";
import Note from "@/view/content/notes/Note.vue";

export default {
  name: "Reminders",
  components: {
    ReminderEditModal,
    Note,
    KTTopMenu,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Not Açıklaması", value: "subject", align: "left" },
        { text: "İşlem", value: "lead_id", align: "left" },
        { text: "Tarih", value: "reminder_date", align: "left" },
        { text: "Saat", value: "reminder_time", align: "left" },
        { text: "Durum", value: "status", align: "left" },
        {
          text: "",
          value: "actions",
          width: "150px",
          sortable: false,
          align: "left",
        },
      ],
      loading: true,
      selectedReminderId: null,
    };
  },
  async mounted() {
    await this.fetchReminders();
    this.loading = false;
    if (this.$route.query.reminder_id !== undefined) {
      const interval = setInterval(() => {
        if (this.$refs[`note-${this.$route.query.reminder_id}`]) {
          this.$refs[
            `note-${this.$route.query.reminder_id}`
          ].$slots.default[0].elm.click();
          clearInterval(interval);
        }
      }, 500);
    }
  },
  methods: {
    ...mapActions([
      FETCH_REMINDERS,
      DELETE_REMINDER,
      DELETE_COMPLETED_REMINDERS,
      COMPLETE_REMINDER_STATUS,
    ]),
    showDeleteModal(reminder) {
      if (reminder.status_text === "Tamamlandı")
        return this.acceptDelete(reminder);
      const params = {
        title: "Seçtiğiniz hatırlatıcı silinecek",
        text: `${reminder.status_text} durumunda ki seçili hatırlatıcı silmek istediğinize emin misiniz?`,
        onConfirm: () => {
          return this.acceptDelete(reminder);
        },
      };
      this.$approvalModal.show(params);
    },
    async acceptDelete(reminder) {
      try {
        await this.deleteReminder(reminder);
        this.selectedReminder = [];
        this.$toast.success("Hatırlatıcı silindi", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    async deleteAllCompletedReminders() {
      try {
        await this.deleteCompletedReminders();
        this.$toast.success("Tamamlanan hatırlatıcılar silindi", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    async setReminderStatusAsComplete(reminder) {
      try {
        await this.completeReminderStatus(reminder);
        this.$toast.success("Hatırlatıcı tamamlandı", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    getReminderStatusText(status) {
      switch (status) {
        case "completed":
          return "Tamamlandı";
        case "pending":
          return "Bekliyor";
        case "in_progress":
          return "Devam Ediyor";
        case "notified":
          return "Bildirildi";
        default:
          return "Bilinmiyor";
      }
    },
    getReminderStatusColor(status) {
      switch (status) {
        case "completed":
          return "success";
        case "pending":
          return "warning";
        case "in_progress":
          return "primary";
        case "notified":
          return "info";
        default:
          return "secondary";
      }
    },
    openReminderEditModal(reminder = {}) {
      let editMode = reminder === {} ? false : true;
      this.$root.$emit("showReminderEditModal", reminder, editMode);
    },
    changeRowBGColor(item) {
      if (item.id == this.selectedReminderId) {
        return "bg-info";
      }
    },
  },
  computed: {
    ...mapGetters(["reminders"]),
    mappedReminders() {
      return this.reminders.map((reminder) => {
        return {
          ...reminder,
          subject: reminder?.note?.content ?? "Açıklama Yok",
          note_id: reminder?.note?.id ?? null,
          note_type: reminder?.note?.note_type ?? null,
          status_text: this.getReminderStatusText(reminder.status),
          reminder_date: reminder.reminder_date
            ? this.$moment(reminder.reminder_date).format("DD/MM/YYYY")
            : "Yok",
          reminder_time: reminder.reminder_time
            ? reminder.reminder_time.slice(0, 5)
            : "Yok",
          lead_name: reminder?.note?.lead?.name ?? "",
          lead_id: reminder?.note?.lead?.id ?? null,
        };
      });
    },
    isSelected() {
      return this.selectedReminder.length > 0;
    },
    areRemindersIncludeCompletedReminders() {
      return (
        this.reminders.filter((reminder) => reminder.status === "completed")
          .length > 0
      );
    },
  },
};
</script>
