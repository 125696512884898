<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <Card :title="title" v-if="dialog" modal>
      <div class="pa-4">
        <v-row>
          <v-col cols="12">
            <CustomDatePicker
              v-model="reminder.reminder_date"
              label="Tarih Seç"
              :type="'disabledBeforeToday'"
            >
            </CustomDatePicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-menu
              ref="timePickerMenu"
              v-model="timePickerMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
              max-height="290px"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="reminder.reminder_time"
                  label="Saat Seç"
                  :prepend-icon="null"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="timePickerMenu"
                v-model="reminder.reminder_time"
                no-title
                full-width
                format="24hr"
                :allowed-hours="allowedHours"
                @click:minute="$refs.timePickerMenu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-content-end">
              <v-btn class="mr-3" color="error" @click="dialog = false"
                >Kapat</v-btn
              >
              <v-btn color="primary" @click="save">Kaydet</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </Card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { defineComponent } from "vue";
import {
  GET_REMINDER,
  CREATE_REMINDER,
  UPDATE_REMINDER,
} from "@/core/services/store/reminders.module";

import Card from "@/view/content/components/Card";
import CustomDatePicker from "@/view/content/components/CustomDatePicker.vue";

export default defineComponent({
  name: "ReminderEditModal",
  components: {
    Card,
    CustomDatePicker,
  },
  data() {
    return {
      timePickerMenu: false,
      dialog: false,
      reminder: {},
      editMode: false,
    };
  },
  mounted() {
    this.$root.$on("showReminderEditModal", (reminder, editMode) => {
      this.reminder = Object.assign({}, reminder);
      this.editMode = editMode;
      this.dialog = true;
    });
  },
  methods: {
    ...mapActions([GET_REMINDER, CREATE_REMINDER, UPDATE_REMINDER]),
    getAllowerdHours() {
      const today = new Date().toISOString().slice(0, 10);
      if (this.date === today) {
        const hours = [];
        const currentHour = new Date().getHours();
        for (let i = currentHour; i < 24; i++) {
          hours.push(i);
        }
        return hours;
      } else {
        const hours = [];
        for (let i = 0; i < 24; i++) {
          hours.push(i);
        }
        return hours;
      }
    },
    async save() {
      if (this.editMode) {
        try {
          await this.updateReminder(this.reminder);
          this.$toast.success("Hatırlatıcı Güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const reminderTime = this.reminder.reminder_time.split(":");
          if (reminderTime.length === 2) {
            this.reminder.reminder_time = `${reminderTime[0]}:${reminderTime[1]}:00`;
          }
          await this.createReminder(this.reminder);
          this.$toast.success("Hatırlatıcı Yaratildi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
      this.closeModal();
      return;
    },
    closeModal() {
      this.reminder = {};
      this.editMode = false;
      this.dialog = false;
    },
  },
  computed: {
    title() {
      if (this.editMode) {
        return "Hatırlatıcı Düzenle";
      }
      return "Hatırlatıcı Ekle";
    },
    allowedHours() {
      return this.getAllowerdHours();
    },
  },
});
</script>
